<template>
  <div class="home-page">
    <Pane>
      <div class="row">
        <div class="col pt-4 pb-3">
          <h2>{{ $t("profile.editingPersonalData") }}</h2>
        </div>
      </div>
      <form @submit.prevent="submitForm()">
        <div class="row pt-4">
          <div class="col d-flex flex-column">
            <div class="row py-3">
              <div class="col-3 align-self-center form-label">Email</div>
              <div class="col-9">
                <input
                  type="text"
                  v-model="data.email"
                  disabled
                  class="form-input"
                />
              </div>
            </div>
            <div v-if="!data.email_verified" class="row pt-1 pb-3">
              <div class="col offset-3">
                <a
                  href="javascript:void(0)"
                  class="form-link"
                  @click.prevent="confirmEmail"
                  >{{ $t("profile.acceptEmailAddress") }}</a
                >
              </div>
            </div>
            <div class="row py-3">
              <div class="col-3 align-self-center form-label">
                {{ $t("common.walletWMZ") }}
              </div>
              <div class="col-9">
                <input type="text" v-model="data.wmr" class="form-input" />
              </div>
            </div>
            <div class="row py-3">
              <div class="col offset-3">
                <a
                  href="javascript:void(0)"
                  class="form-link"
                  @click.prevent="isChangePasswordModalShown = true"
                  >{{ $t("profile.changePassword") }}</a
                >
              </div>
            </div>
            <div class="row py-3 mt-auto">
              <div class="col-3 align-self-center form-label">
                {{ $t("profile.uiLanguage") }}
              </div>
              <div class="col-9">
                <select v-model="language" class="form-select">
                  <option value="ru">Русский</option>
                  <option value="en">English</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row py-3">
              <div class="col-3 align-self-center form-label">
                {{ $t("common.name") }}
              </div>
              <div class="col-9">
                <input
                  type="text"
                  v-model="data.name"
                  class="form-input"
                  :class="{
                    'form-input--is-invalid': formErrors.hasOwnProperty('name'),
                  }"
                />
              </div>
            </div>
            <div class="row py-3">
              <div class="col-3 align-self-center form-label">
                {{ $t("common.lastname") }}
              </div>
              <div class="col-9">
                <input
                  type="text"
                  v-model="data.last_name"
                  class="form-input"
                  :class="{
                    'form-input--is-invalid':
                      formErrors.hasOwnProperty('last_name'),
                  }"
                />
              </div>
            </div>
            <div class="row py-3">
              <div class="col-3 align-self-center form-label">
                {{ $t("common.company") }}
              </div>
              <div class="col-9">
                <input
                  type="text"
                  v-model="data.company"
                  class="form-input"
                  :class="{
                    'form-input--is-invalid':
                      formErrors.hasOwnProperty('company'),
                  }"
                />
              </div>
            </div>
            <div class="row py-3">
              <div class="col-3 align-self-center form-label">Telegram</div>
              <div class="col-9">
                <input
                  type="text"
                  v-model="data.telegram"
                  class="form-input"
                  :class="{
                    'form-input--is-invalid':
                      formErrors.hasOwnProperty('telegram'),
                  }"
                />
              </div>
            </div>
            <div class="row py-3">
              <div class="col-3 align-self-center form-label">
                {{ $t("profile.reserveEmail") }}
              </div>
              <div class="col-9">
                <input
                  type="text"
                  v-model="data.alternative_email"
                  class="form-input"
                  :class="{
                    'form-input--is-invalid':
                      formErrors.hasOwnProperty('alternative_email'),
                  }"
                />
              </div>
              <div
                v-if="formErrors.hasOwnProperty('alternative_email')"
                class="mt-2 offset-3 col form-error-list"
              >
                {{
                  formErrors.alternative_email[0].includes("Not a valid email")
                    ? $t("notification.incorrectEmail")
                    : $t("notification.errorEmailAlreadyUse")
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col pt-4 pb-3">
            <h2>{{ $t("notification.notifications") }}</h2>
          </div>
        </div>
        <div class="row py-3">
          <div class="col-11">
            <div class="d-flex align-self-center">
              <input
                type="checkbox"
                v-model="data.audit_email_notification"
                class="form-check"
              />
              <span class="pl-4" style="padding-top: 0.15rem">
                {{ $t("profile.emailNotification") }}
              </span>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col">
            <div class="d-flex justify-content-end">
              <button type="submit" class="form-btn">
                {{ $t("common.saveChanges") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Pane>
    <Modal :shown.sync="isConfirmEmailModal">
      <h2 slot="header" class="modal-header">
        {{ $t("notification.titleSentEmail") }}
      </h2>
      <div slot="body">
        <div class="modal-text">
          <p>
            {{ $t("notification.textSentEmail") }}

            <strong style="display: block; padding: 10px 0">
              {{ $t("notification.textSentEmail2") }}
            </strong>
          </p>

          <span class="warning">
            <p style="padding-top: 20px">
              {{ $t("notification.textSentEmail3") }}
            </p>
          </span>
        </div>
      </div>
      <div slot="footer">
        <div class="footer-btn">
          <button class="form-btn" @click="isConfirmEmailModal = false">
            Ok
          </button>
        </div>
      </div>
    </Modal>
    <Modal :shown.sync="isChangePasswordModalShown">
      <h2 slot="header" class="offset-3 pb-2">
        {{ $t("profile.changePassword") }}
      </h2>
      <form slot="body" @submit.prevent="passwordChangeFormSubmit()">
        <div class="row py-3">
          <div class="col-3 align-self-center form-label">
            {{ $t("auth.oldPassword") }}
          </div>
          <div class="col-9">
            <input
              type="password"
              v-model="passwordChangeForm.password"
              class="form-input"
            />
          </div>
        </div>
        <div class="row py-3">
          <div class="col-3 align-self-center form-label">
            {{ $t("auth.newPassword") }}
          </div>
          <div class="col-9">
            <input
              type="password"
              v-model="passwordChangeForm.new_password1"
              class="form-input"
            />
          </div>
        </div>
        <div class="row py-3">
          <div class="col-3 align-self-center form-label">
            {{ $t("auth.repeatPassword") }}
          </div>
          <div class="col-9">
            <input
              type="password"
              v-model="passwordChangeForm.new_password2"
              class="form-input"
            />
          </div>
        </div>
        <div class="row pt-4 pb-3">
          <div class="offset-3 col-9">
            <div class="d-flex">
              <button type="submit" class="form-btn">
                {{ $t("common.toChange") }}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div slot="footer" />
    </Modal>
  </div>
</template>

<script>
import Pane from '@/components/decorative/Pane.vue';
import Modal from '@/components/Modal.vue';
import apiUrl from '@/utils/apiUrl';
import axios from 'axios';
import { Notification } from 'element-ui';

export default {
  name: 'ProfileEdit',
  components: {
    Pane,
    Modal,
  },
  data() {
    return {
      data: {
        name: '',
        last_name: '',
        phone: '',
        email: '',
        email_verified: false,
        alternative_email: '',
        company: '',
        about: '',
        facebook: '',
        icq: '',
        site: '',
        skype: '',
        telegram: '',
        vk: '',
        wmr: '',
        audit_email_notification: false,
        language: '',
      },
      oldData: {},
      passwordChangeForm: {
        new_password1: '',
        new_password2: '',
        password: '',
      },
      formErrors: {},
      isChangePasswordModalShown: false,
      isConfirmEmailModal: false,
    };
  },
  computed: {
    language: {
      get() {
        return this.$i18n.locale;
      },
      set(newValue) {
        this.$i18n.locale = newValue;
        this.data.language = newValue;
      },
    },
  },
  mounted() {
    this.$store
      .dispatch('authDataRequest', {
        action: 'me',
        method: 'GET',
      })
      .then((resp) => {
        this.data = resp;
        this.oldData = { ...resp };
      });
  },
  methods: {
    getUpdates() {
      let updatedObject = {};
      Object.keys(this.data).forEach((k) => {
        updatedObject = {
          ...updatedObject,
          ...(this.oldData[k] !== this.data[k] && { [k]: this.data[k] }),
        };
      });
      return updatedObject;
    },
    confirmEmail() {
      axios({
        url: apiUrl('application/confirm/email/'),
        method: 'post',
      })
        .then(() => {
          this.isConfirmEmailModal = true;
        })
        .catch((err) => {
          this.formErrors = err;
          Notification.error({
            title: this.$t('notification.error'),
            message: this.$t('notification.errorEmailNotSent'),
          });
        });
    },
    submitForm() {
      const data = this.getUpdates();
      if (Object.entries(data).length > 0) {
        this.$store
          .dispatch('authDataRequest', {
            action: 'me',
            method: 'PATCH',
            data,
          })
          .then((resp) => {
            Notification.success({
              title: this.$t('notification.success'),
              message: this.$t('notification.dataUpdated'),
            });
            this.data = resp;
            this.oldData = { ...resp };
          })
          .catch((err) => {
            this.formErrors = err;
            Notification.error({
              title: this.$t('notification.error'),
              message: this.$t('notification.errorCheckCorrectInput'),
            });
          });
      } else {
        Notification.warning({
          title: this.$t('notification.nothingUpdate'),
          message: this.$t('notification.errorMadeAnyChanges'),
        });
      }
    },
    passwordChangeFormSubmit() {
      this.$store
        .dispatch('authDataRequest', {
          action: 'password/change',
          method: 'POST',
          data: this.passwordChangeForm,
        })
        .then(
          () => {
            this.isChangePasswordModalShown = false;
            Notification.success({
              title: this.$t('notification.success'),
              message: this.$t('notification.successPasswordUpdate'),
            });
            this.passwordChangeForm = {
              new_password1: '',
              new_password2: '',
              password: '',
            };
          },
          () => {
            Notification.error({
              title: this.$t('notification.error'),
              message: this.$t('notification.errorOldPasswordOrNewNoMatch'),
            });
          },
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  text-align: center;
}
.modal-text {
  text-align: center;
  line-height: 24px;
  padding: 20px 0 0 0;
  .warning {
    line-height: 16px;
    font-size: 10px;
    text-align: justify;
    &:after {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }
}
.footer-btn {
  text-align: center;
}
</style>
